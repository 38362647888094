import React from "react";

import styles from "./PreFooter.module.scss";
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";
import footer from '../../assets/imageBox/Footer.png'
import PFooter from '../../assets/imageBox/PFooter.png'

export const PreFooter: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.text}>don’t miss your chance to join pixie metaverse at an early stage to get maximum profit when it launches</div>
            <img className={styles.PFooter} src={PFooter} alt="" />
            <div className={styles.blockGradLeft}>

            </div>
            <img className={styles.gard} src={footer} alt="" />
            <div className={styles.blockGradRight}>

            </div>
        </div>
    );
};

