import { AxiosError } from "axios";
import { ethers } from "ethers";

export interface IEthereumAccount {
    walletAddress: string;
    signature: string;
}

export interface IEthereumAccountInfo {
    provider: ethers.providers.Web3Provider;
    signer: ethers.providers.JsonRpcSigner;
    walletAddress: string;
}

const paramAval =
{
    chainId: '0x' +
        Number(43114).toString(16),
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    chainName: 'Avalanche'
}
const paramVelas = {
    chainId: '0x' +
        Number(106).toString(16),
    rpcUrls: ['https://evmexplorer.velas.com/rpc'],
    chainName: 'Velas Network'
}
export const getEthereumAccountInfo =
    async (CHAIN_ID: number): Promise<IEthereumAccountInfo | void> => {
        try {
            const ethereum = window.ethereum;
            if (!ethereum) {
                return alert('Install a MetaMask Wallet')
            }
            console.log(window.ethereum.networkVersion)
            if (
                Number(window.ethereum.networkVersion) !==
                Number(CHAIN_ID)
            ) {
                alert('It is necessary to change the blockchain network')
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [
                            {
                                chainId:
                                    '0x' +
                                    Number(CHAIN_ID).toString(16),
                            },
                        ],
                    });
                } catch (error: any) {
                    // This error code indicates that the chain has not been added to MetaMask
                    // if it is not, then install it into the user MetaMask
                    let paramsChain
                    if (error.code === 4902) {
                        switch (Number(CHAIN_ID)) {
                            case 43114:
                                paramsChain = paramAval
                                break;
                            case 106:
                                paramsChain = paramVelas
                                break;

                        }
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    paramsChain
                                ],
                            });
                        } catch (addError) {
                            console.log(addError);
                        }
                    }
                    console.log(error);
                }
            }
            // if (
            //     Number(window.ethereum.networkVersion) ===
            //     Number(CHAIN_ID)
            // ) {

            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const provider: ethers.providers.Web3Provider =
                new ethers.providers.Web3Provider(
                    ethereum as ethers.providers.ExternalProvider
                );
            const signer: ethers.providers.JsonRpcSigner = provider.getSigner();
            const walletAddress: string = await signer.getAddress();
            return { provider, signer, walletAddress };
            // }

        } catch (e: AxiosError | unknown) {
            alert((e as AxiosError).message);

        }
    };


export const getInfoMetaMask = async (): Promise<IEthereumAccountInfo | void> => {
    try {
        const ethereum = window.ethereum;
        console.log(Number(ethereum.networkVersion))
        if (Number(ethereum.networkVersion) === 97) {
            await ethereum.request({ method: 'eth_requestAccounts' });

            const provider: ethers.providers.Web3Provider =
                new ethers.providers.Web3Provider(
                    ethereum as ethers.providers.ExternalProvider
                );
            const signer: ethers.providers.JsonRpcSigner = provider.getSigner();
            const walletAddress: string = await signer.getAddress();
            return { provider, signer, walletAddress };
        } else {
            alert('You need to switch to the BNB test network')
            return
        }


    } catch (e: AxiosError | unknown) {
        alert((e as AxiosError).message);

    }
}


