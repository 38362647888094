import { AxiosResponse } from "axios";
import { IAuth } from "../types/auth";
import agent from "./agent/axios";

export const fetchAuthLogin = (
  nickname: string
): Promise<AxiosResponse<IAuth.Tokens>> => {
  return agent.post("/auth/login", {
    nickname: nickname,
  });
};

export const fetchAuthLogout = (): Promise<AxiosResponse<any>> => {
  return agent.post("/auth/logout");
};

export const fetchAuthRefresh = (
  refreshToken: string
): Promise<AxiosResponse<any>> => {
  return agent.post(`/auth/refresh`, {
    refreshToken: refreshToken,
  });
};
