import React from "react";

import styles from "./MysteryBoxAdvantages.module.scss";
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";
import P2Block from '../../assets/imageBox/P2Block.png'
import { modal } from "../../components/modal";
import progressBar from '../../assets/image/ProgressBar.svg'
import { ModalMysteryBox } from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";
import cl from 'classnames'

export const MysteryBoxAdvantages: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.subTitle}>
                mystery box advantages
            </div>
            <div className={styles.blocks}>
                <div className={cl(styles.block, styles.block1)}>
                    <div className={cl(styles.blockTitle)}>
                        3 Types of NFTs
                    </div>
                    <div className={cl(styles.blockText)}>
                        You can find a Land, House or Licence inside. 4010 NFTs will be visible in the Metaverse
                    </div>
                </div>
                <div className={cl(styles.block, styles.block2)}>
                    <div className={cl(styles.blockTitle)}>
                        100% utility
                    </div>
                    <div className={cl(styles.blockText)}>
                        Every NFT out of 15010 will bring a passive income in USDT every 5 minutes once the first game is played on the platform                    </div>
                </div>
                <div className={cl(styles.block, styles.block3)}>
                    <div className={cl(styles.blockTitle)}>
                        nft will pay off
                    </div>
                    <div className={cl(styles.blockText)}>
                        Just like real estate in Vegas, your NFT can be paid off really quick, in a matter of days or weeks
                    </div>
                </div>
            </div>
            <div className={styles.gradBlock}>
                <div className={cl(styles.gradBlockTitle)}>
                    100 NFTs will have cash prizes up to $1000 each
                </div>
                <div className={cl(styles.gradBlockText)}>
                    In Pixie, your earning opportunities are huge. So let’s start from the sale itself. The results will be revealed after the last sale round completion
                </div>
            </div>
            {/* <div className={styles.dots}>
                <div className={styles.item}>
                    <div className={styles.dot} />
                    AI-generated

                </div>
                <div className={styles.item}>
                    <div className={styles.dot} />
                    absolutely unique
                </div>
                <div className={styles.item}>
                    <div className={styles.dot} />
                    minted on binance blockchain
                </div>
            </div> */}
            <div className={styles.btn}>
                <ButtonActive onClick={() => modal.open(<ModalMysteryBox />)}>buy a box</ButtonActive>
            </div>
            <img className={styles.img} src={P2Block} alt="" />
            <img className={styles.progressBar} src={progressBar} alt="" />
        </div>
    );
};

