import React, { ButtonHTMLAttributes } from "react";
import styles from "./ButtonPassive.module.scss";
import img from '../../assets/image/BorderButtonPassive.png'

interface IButtonPassiveProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: any;
}


export const ButtonPassive: React.FC<IButtonPassiveProps> = ({ children, ...props }) => {

    return (
        <button {...props} className={styles.btn}>
            <div className={styles.children}>
                {children}
            </div>

            <div className={styles.border}>
              <img src={img} alt="" />
            </div>
        </button>
    );
};

