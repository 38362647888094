import React from 'react'
import './App.scss';

import { Routes, Route } from 'react-router-dom';
import { MainPage } from './MainPage';
import { MysteryBoxPage } from './MysteryBoxPage';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainPage />
        }
      />
      <Route
        path="/nft"
        element={
          <MysteryBoxPage />
        }
      />
    </Routes>
  );
}

export default App;
