import React, { ButtonHTMLAttributes } from "react";
import styles from "./ButtonActive.module.scss";

interface IButtonActiveProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: any;
}

export const ButtonActive: React.FC<IButtonActiveProps> = ({ children, ...props }) => {

    return (
        <button {...props} className={styles.btn}>
            <div className={styles.children}>
                {children}
            </div>
            <div className={styles.border}>
                <svg width="173" height="58" viewBox="0 0 173 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_267_85)">
                        <rect width="168" height="53" fill="#E74FF1" />
                    </g>
                    <rect x="9" y="40" width="5" height="5" fill="#B5E627" />
                    <rect x="9" y="8" width="5" height="5" fill="#B5E627" />
                    <rect x="153" y="8" width="5" height="5" fill="#B5E627" />
                    <rect x="153" y="40" width="5" height="5" fill="#B5E627" />
                    <defs>
                        <filter id="filter0_d_267_85" x="0" y="0" width="173" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dx="5" dy="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.709804 0 0 0 0 0.901961 0 0 0 0 0.152941 0 0 0 1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_267_85" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_267_85" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </button>
    );
};

