import React, { useState } from "react";
import styles from "./ModalEmail.module.scss";
import { ButtonActive } from "../../../ButtonActive/ButtonActive";
import { modal } from "../../ModalConnector";
import { send } from 'emailjs-com';

export const ModalEmail: React.FC = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telegram, setTelegram] = useState('')

    const [step, setStep] = useState(1)


    const sendMe = async () => {
        // modal.close()\
        await send(
            'service_lltc4os',
            'template_rs4c2mj',
            {
                from_name: name,
                email: email,
                telegram: telegram,
            },
            'd6RF_xYbYTsh5a2wy'
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setStep(2)
            setTimeout(() => {
                modal.close()
            }, 3000);
            setName('')
            setEmail('')
            setTelegram('')
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });

    }
    return (
        <div className={styles.content}>
            {step === 1 ?
                <div className={styles.forms}>
                    <div className={styles.titleMain}>
                        Enter the data
                    </div>
                    <div className={styles.inputBlock}>
                        <div className={styles.title}>
                            Name
                        </div>
                        <input type="text" onChange={(e) => {
                            setName(e.target.value)
                        }} />
                    </div>
                    <div>
                        <div className={styles.title}>
                            Email
                        </div>
                        <input type="text" onChange={(e) => {
                            setEmail(e.target.value)
                        }} />
                    </div>
                    <div>
                        <div className={styles.title}>
                            Telegram
                        </div>
                        <input type="text" onChange={(e) => {
                            setTelegram(e.target.value)
                        }} />
                    </div>
                    <div className={styles.btn}>
                        <ButtonActive onClick={sendMe}>Send</ButtonActive>
                    </div>
                </div>
                :
                <div className={styles.successfully}>
                    Successfully!
                </div>
            }
        </div>
    );
};

