import React from "react";
import styles from "./SectionsWhatAre.module.scss";
import img from '../../assets/image/ImageGirls.png'
import flare from '../../assets/image/flares/flareWhatAre.png'
import pixels from '../../assets/image/ImageGirls__pixel.png'
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";

export const SectionsWhatAre: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.left}>
                <img src={img} alt="" />
                <img className={styles.pixels} src={pixels} alt="" />
                
            </div>
            <div className={styles.right}>
                <div className={styles.title}>
                    what are pixies
                    <svg width="539" height="93" viewBox="0 0 539 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="33" y1="3.5" x2="2" y2="3.5" stroke="#AADC18" stroke-width="7" />
                        <line x1="3.5" y1="8.17705e-08" x2="3.49999" y2="92" stroke="#AADC18" stroke-width="7" />
                        <line x1="1" y1="89.5" x2="33" y2="89.5" stroke="#AADC18" stroke-width="7" />
                        <line y1="-3.5" x2="31" y2="-3.5" transform="matrix(1 4.6726e-08 1.63565e-07 -1 506 0)" stroke="#AADC18" stroke-width="7" />
                        <line y1="-3.5" x2="92" y2="-3.5" transform="matrix(8.17826e-08 1 1 -2.3363e-08 539 0)" stroke="#AADC18" stroke-width="7" />
                        <line y1="-3.5" x2="32" y2="-3.5" transform="matrix(-1 0 0 1 538 93)" stroke="#AADC18" stroke-width="7" />
                    </svg>

                </div>
                <div className={styles.flare}>
                    <img src={flare} alt="" />
                </div>
                <div className={styles.subtitle}>
                    <div className={styles.paragraph}>
                        Pixies are creatures with a centuries-old history known for their passion for gambling and easy enrichment. They appeared in the crypto world in order to help you evlolve and get what you want faster than with any other platform.
                    </div>
                    <div>
                        You can empower your Pixie with the unique skills which will help you get more profit. Each Pixie is a unique NFT which you can own and lend to other players who have a feel for the game and are ready to make you some money.
                    </div>
                </div>

                <div className={styles.btns}>
                    <ButtonActive onClick={() => { window.open("https://docs.pixie.fun/pixie-whitepaper/introduction/what-is-pixie", "_blank") }}>learn more</ButtonActive>
                </div>

            </div>
        </div>
    );
};

