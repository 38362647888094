import React, { useState } from "react";
import styles from "./Header.module.scss";
import { SvgHeart } from "../svg/Header/SvgHeart";
import { SvgMenu } from "../svg/SvgMenu";
import { Logo } from "../Logo/Logo";
import { SvgIconCloseMenu } from "../svg/SvgIconCloseMenu";
import { SocialNetworks } from "../SocialNetworks/SocialNetworks";
import btn from '../../assets/image/BtnMenu.png'
import googlePlay from '../../assets/googlePlay.svg'
import newPoster from '../../assets/image/NewHeader.svg'
import { useNavigate } from "react-router-dom";
import cl from 'classnames'

export const Header: React.FC = () => {

    const [isOpenMenu, setisOpenMenu] = useState(false)
    const navigate = useNavigate();
    return (
        <header>
            <div className={styles.left}>
                <div onClick={() => { navigate('/') }}>
                    <Logo />
                </div>

                <div className={styles.tabs}>
                    <a href="#about" onClick={() => { navigate('/') }}>
                        About
                    </a>
                    <a className={styles.__mobile} onClick={() => { navigate('/nft') }}>
                        {/* href="#nft" */}
                        nft
                        <img className={styles.newPoster} src={newPoster} alt="" />
                    </a>
                    <a href="#ecosystem" onClick={() => { navigate('/') }}>
                        ecosystem
                    </a>
                    <a href="#tokenomics" onClick={() => { navigate('/') }}>
                        Tokenomics
                    </a>
                    <a href="#roadmap" onClick={() => { navigate('/') }}>
                        Roadmap
                    </a>
                    <a className={styles.googlePlay} onClick={() => {
                        window.open("https://play.google.com/store/apps/details?id=justapps.studios.pixie&pli=1", "_blank");
                    }}>
                        Mobile app
                        <img src={googlePlay} height={30} alt="" />
                    </a>


                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.hearts}>
                    <div className={styles.hearts1}>
                        <SvgHeart />
                    </div>
                    <div className={styles.hearts2}>
                        <SvgHeart />
                    </div>
                    <div className={styles.hearts3}>
                        <SvgHeart />
                    </div>
                </div>
            </div>
            <div className={styles.menu} onClick={() => {
                setisOpenMenu(true)
            }}>
                <SvgMenu />
            </div>
            {isOpenMenu &&
                <div className={styles.menu_block}>
                    <div className={styles.close} onClick={() => {
                        setisOpenMenu(false)
                    }}>
                        <SvgIconCloseMenu />
                    </div>
                    <div className={styles.tabs__mobile}>
                        <a href="#" className={styles.__mobile}>
                            Home
                        </a>
                        <a className={styles.__mobile} onClick={() => { navigate('/nft') }}>
                            Nft
                        </a>
                        <a href="#mission" className={styles.__mobile}>
                            Mission
                        </a>
                        <a href="#ecosystem" className={styles.__mobile}>
                            Ecosystem
                        </a>
                        <a href="#tokenomics" className={styles.__mobile}>
                            Economy
                        </a>
                        <a href="#" className={styles.__mobile}>
                            Partners
                        </a>
                        <a className={cl(styles.__mobile, styles.googlePlay)} onClick={() => {
                            window.open("https://play.google.com/store/apps/details?id=justapps.studios.pixie&pli=1", "_blank");
                        }}>
                            Mobile app
                            <img src={googlePlay} height={30} alt="" />
                        </a>
                        <SocialNetworks />
                        <div className={styles.btn} onClick={() => { window.open("https://game.pixie.fun/", "_blank") }}>
                            <img src={btn} alt="" />
                        </div>

                    </div>
                </div>
            }

        </header>
    );
};

