import React from "react";
import img1 from '../../assets/image/Coins5L.png'
import img2 from '../../assets/image/Coins5R.png'
import styles from "./Sections5Mechanisms.module.scss";


export const Sections5Mechanisms: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.title}>
                <div className={styles.title__text} >
                    5 earning mechanisms
                </div>
                <div className={styles.title__backBlock2} />
                <div className={styles.title__backBlock1} />
            </div>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.card__top}>
                        <div className={styles.card__topText}>
                            Stay2Earn
                        </div>
                        <svg width="355" height="82" viewBox="0 0 355 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9856 0H332.014V18.2222H355L355 82H0V18.2222H22.9856V0Z" fill="url(#paint0_linear_190_9)" />
                            <defs>
                                <linearGradient id="paint0_linear_190_9" x1="177.5" y1="0" x2="177.5" y2="82" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B5E627" />
                                    <stop offset="1" stop-color="#E74FF1" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.card__text}>
                        Earn money just by being in the metaverse and completing simple tasks for your Pixie Family. Your Pixie Family will be able to easily pick up the daily pools, even if you were just in the virtual city.
                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.card__top}>
                        <div className={styles.card__topText}>
                            Play2Earn
                        </div>
                        <svg width="355" height="82" viewBox="0 0 355 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9856 0H332.014V18.2222H355L355 82H0V18.2222H22.9856V0Z" fill="url(#paint0_linear_190_9)" />
                            <defs>
                                <linearGradient id="paint0_linear_190_9" x1="177.5" y1="0" x2="177.5" y2="82" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B5E627" />
                                    <stop offset="1" stop-color="#E74FF1" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.card__text}>
                        Make predictions or participate in existing games to increase your capital. There will be no losers. If you have not guessed where the price will go, then get special FunPIX tokens that will have their own usecases and value.                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.card__top}>
                        <div className={styles.card__topText}>
                            Own2Earn
                        </div>
                        <svg width="355" height="82" viewBox="0 0 355 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9856 0H332.014V18.2222H355L355 82H0V18.2222H22.9856V0Z" fill="url(#paint0_linear_190_9)" />
                            <defs>
                                <linearGradient id="paint0_linear_190_9" x1="177.5" y1="0" x2="177.5" y2="82" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B5E627" />
                                    <stop offset="1" stop-color="#E74FF1" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.card__text}>
                        Own real estate in the metaverse and get income every time someone plays in your house. Invite people to your place so that your house brings a constant income. Advertise on your house just as on Times Square. Create your own monopoly that can bring you income even if you are not an active participant.                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.card__top}>
                        <div className={styles.card__topText}>
                            Invite2Earn
                        </div>
                        <svg width="355" height="82" viewBox="0 0 355 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9856 0H332.014V18.2222H355L355 82H0V18.2222H22.9856V0Z" fill="url(#paint0_linear_190_9)" />
                            <defs>
                                <linearGradient id="paint0_linear_190_9" x1="177.5" y1="0" x2="177.5" y2="82" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B5E627" />
                                    <stop offset="1" stop-color="#E74FF1" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.card__text}>
                        Thanks to the creation of a referral bonuses pool, you will be able to receive a part of your referral's deposit, regardless of the outcome of the game. You can also host games and get a percentage of the whole pool of game winners.
                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.card__top}>
                        <div className={styles.card__topText}>
                            Delegate2Earn
                        </div>
                        <svg width="355" height="82" viewBox="0 0 355 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9856 0H332.014V18.2222H355L355 82H0V18.2222H22.9856V0Z" fill="url(#paint0_linear_190_9)" />
                            <defs>
                                <linearGradient id="paint0_linear_190_9" x1="177.5" y1="0" x2="177.5" y2="82" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#B5E627" />
                                    <stop offset="1" stop-color="#E74FF1" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.card__text}>
                        Buy whole neighbourhoods and rent your buildings to other more active players in exchange for a share of the income that they will bring you from bets. You, as the owner, will receive 40% of the income generated by the players to whom you delegated your Metaverse real estate.                    </div>
                </div>
            </div>
            <div className={styles.coinsL}>
                <img src={img1} alt="" />
            </div>
            <div className={styles.coinsR}>
                <img src={img2} alt="" />
            </div>
        </div>
    );
};

