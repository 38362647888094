import React from "react";
export const SvgMenu: React.FC = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.9167 9.91669H7.08335" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round" />
            <path d="M26.9167 17H12.75" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round" />
            <path d="M26.9167 24.0833H18.4167" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round" />
        </svg>

    );
};
