import ReactDOM from "react-dom";
import React from "react";
import cl from "classnames";
import styles from "./modal.module.scss";
import { SvgIconCloseMenu } from "../svg/SvgIconCloseMenu";
// import { SvgIconClose } from "../svg/svgIconClose";

interface IModal {
  active: boolean;
  setActive: (active: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

export const Modal: React.FC<IModal> = ({
  active,
  setActive,
  className,
  children,
}) => {
  const [domReady, setDomReady] = React.useState(false);

  React.useEffect(() => {
    setDomReady(true);
  }, []);

  const modalElem = document.getElementById("modal");

  return domReady && modalElem
    ? ReactDOM.createPortal(
      <div
        className={cl(styles.modal, {
          [styles.active]: active,
        })}
        // onMouseUp={(event) => event.stopPropagation()}
        onMouseDown={() => setActive(false)}
      >
        <div
          className={cl(styles.modal__content, {
            [styles.active]: active,
          })}
          onMouseDown={(event) => event.stopPropagation()}
        >
          <div className={styles.window_default}>{children}</div>

          <button
            onClick={() => setActive(false)}
            type="button"
            className={styles.modal__close_button}
          >
            <SvgIconCloseMenu />

          </button>
        </div>
      </div>,
      modalElem
    )
    : null;
};

export default Modal;
