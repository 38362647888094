import React from "react";
import styles from "./SectionsRoadmap.module.scss";
import cl from "classnames";
import HomeAndTree from '../../assets/image/HomeAndTree.png'
import ImageGirls from '../../assets/image/ImageGirls1.png'
import ImageSword from '../../assets/image/Sword.png'
import ImageFlask from '../../assets/image/Flask.png'
import ImageCoins from '../../assets/image/Coins.png'
import { SvgRoadmapLine } from "./SvgRoadmapLine";
import { RoadmapStage } from "../../components/RoadmapStage/RoadmapStage";
import { RoadmapStep } from "../../components/RoadmapStep/RoadmapStep";


export const SectionsRoadmap: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.title}>
                roadmap
                <svg width="306" height="93" viewBox="0 0 306 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="18.7347" y1="3.5" x2="1.13542" y2="3.5" stroke="#E74FF1" stroke-width="7" />
                    <line x1="3.5" y1="8.17705e-08" x2="3.49999" y2="92" stroke="#E74FF1" stroke-width="7" />
                    <line x1="0.567719" y1="89.5" x2="18.7347" y2="89.5" stroke="#E74FF1" stroke-width="7" />
                    <line y1="-3.5" x2="17.5993" y2="-3.5" transform="matrix(1 8.23049e-08 9.28589e-08 -1 287.265 0)" stroke="#E74FF1" stroke-width="7" />
                    <line y1="-3.5" x2="92" y2="-3.5" transform="matrix(4.64295e-08 1 1 -4.11525e-08 306 0)" stroke="#E74FF1" stroke-width="7" />
                    <line y1="-3.5" x2="18.167" y2="-3.5" transform="matrix(-1 0 0 1 305.432 93)" stroke="#E74FF1" stroke-width="7" />
                </svg>
            </div>
            <div className={styles.flare}>
                <svg width="1592" height="1308" viewBox="0 0 1592 1308" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_111_10)">
                        <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
                    </g>
                    <g filter="url(#filter1_f_111_10)">
                        <circle cx="980.5" cy="696.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
                    </g>
                    <defs>
                        <filter id="filter0_f_111_10" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_111_10" />
                        </filter>
                        <filter id="filter1_f_111_10" x="369" y="85" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_111_10" />
                        </filter>
                    </defs>
                </svg>

            </div>
            <div className={styles.oneArrow}>
                <svg width="31" height="155" viewBox="0 0 31 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 31L28.9234 7.75H2.07661L15.5 31Z" fill="white" />
                    <line x1="16.5" y1="65" x2="16.5" y2="155" stroke="white" stroke-width="5" stroke-dasharray="13 13" />
                </svg>
            </div>


            <div className={cl(styles.stage, styles.stage0)}>
                <div className={styles.titleLeft}>
                    <RoadmapStage title={"ongoing"} status={'in process'} />
                </div>
                <div className={styles.stage0__content}>
                    <div className={styles.stage0__content_row}>
                        <RoadmapStep>
                            Live forecasts for the BTC price without registration and with an instant withdrawal
                        </RoadmapStep>
                        <RoadmapStep>
                            The first NFT collection ‘Origins NFT’ drop
                        </RoadmapStep>
                        <RoadmapStep>
                            Creation of the winners fund (80%) and tokenomics (20%)
                        </RoadmapStep>
                    </div>
                    <div className={styles.stage0__content_row}>
                        <RoadmapStep>
                            Community creation
                        </RoadmapStep>
                        <RoadmapStep>
                            Pixies demo
                        </RoadmapStep>
                    </div>
                </div>
                <div className={styles.stage0__img}>
                    <img src={HomeAndTree} alt="" />
                </div>
            </div>
            <SvgRoadmapLine />
            <div className={cl(styles.stage, styles.stage1)}>
                <div className={styles.titleRigth}>
                    <RoadmapStage title={"stage 1"} status={'q1 ‘ 2023'} />
                </div>
                <div className={styles.stage1__img}>
                    <img src={ImageGirls} alt="" />
                </div>
                <div className={styles.stage1__flare}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1124)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1124" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1124" />
                            </filter>
                        </defs>
                    </svg>

                </div>
                <div className={styles.stage1__content}>
                    <RoadmapStep>
                        Adding live lines in ETH, BTC
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of daily contests for Pixie teams
                    </RoadmapStep>
                    <RoadmapStep>
                        Referral program launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Opportunity to design and decorate the interior and exterior of your house
                    </RoadmapStep>
                    <RoadmapStep>
                        Home improvement opportunity (make it bigger, increase the population and make more profit)
                    </RoadmapStep>
                    <RoadmapStep>
                        Opportunity to build a house on your land to create a new home
                    </RoadmapStep>
                    <RoadmapStep>
                        Gaming license for a house to get profit for making bets
                    </RoadmapStep>
                    <RoadmapStep>
                        Diamond Live program launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of the formed Pixie fund pool  (winners fund - 80% and tokenomics - 20%)
                    </RoadmapStep>
                    <RoadmapStep>
                        NFT Buildings collection drop
                    </RoadmapStep>
                    <RoadmapStep>
                        APR / APY calculator
                    </RoadmapStep>
                    <RoadmapStep>
                        PIX token sale
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of new features  for all Pixie types (e.g. jewelry)  to customize your Pixie
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of FunPIX and compensation for the defeated
                    </RoadmapStep>
                    <RoadmapStep>
                        Purchase and ownership of land in the Pixie Metaverse
                    </RoadmapStep>
                </div>
            </div>
            <SvgRoadmapLine />
            <div className={cl(styles.stage, styles.stage2)}>
                <div className={styles.titleLeft}>
                    <RoadmapStage title={"stage 2"} status={'q2 ‘ 2023'} />
                </div>
                <div className={styles.stage2__flare1}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1128)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1128" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1128" />
                            </filter>
                        </defs>
                    </svg>

                </div>
                <div className={styles.stage2__flare2}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1125)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1125" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1125" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className={styles.stage2__content}>
                    <RoadmapStep>
                        Adding live lines in other top coins
                    </RoadmapStep>
                    <RoadmapStep>
                        Adding new coins to standard bets
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of standard bets
                    </RoadmapStep>
                    <RoadmapStep>
                        Lottery V.2
                    </RoadmapStep>
                    <RoadmapStep>
                        Farming launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Standard bets in BTC
                    </RoadmapStep>
                    <RoadmapStep>
                        FunPIX lottery launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Burning mechanism launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of Pixie and forecast visual component
                    </RoadmapStep>
                    <RoadmapStep>
                        Leaderboards of bid makers and participants
                    </RoadmapStep>
                    <RoadmapStep>
                        New metaverse demo
                    </RoadmapStep>
                    <RoadmapStep>
                        Opportunity to run across your land piece and inside your house
                    </RoadmapStep>

                </div>
            </div>
            <SvgRoadmapLine />
            <div className={cl(styles.stage, styles.stage3)}>
                <div className={styles.titleRigth}>
                    <RoadmapStage title={"stage 3"} status={'q3 ‘ 2023'} />
                </div>

                <div className={styles.stage3__flare1}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1129)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1129" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1129" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className={styles.stage3__flare2}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1126)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1126" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1126" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className={styles.stage3__img1}>
                    <img src={ImageSword} alt="" />
                </div>
                <div className={styles.stage3__img2}>
                    <img src={ImageFlask} alt="" />
                </div>
                <div className={styles.stage3__content}>
                    <RoadmapStep>
                        Metaverse launch
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of Eras for standard bets
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of lending programs for the purchases inside Pixie metaverse
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of Pixies families grades
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of no-loss bids
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of single Pixies grades
                    </RoadmapStep>
                    <RoadmapStep>
                        NFT marketplace for selling game characters, their equipment, skills, names, property, land, etc.
                    </RoadmapStep>
                    <RoadmapStep>
                        Launch of creators grades
                    </RoadmapStep>
                    <RoadmapStep>
                        DAO introduction
                    </RoadmapStep>
                    <RoadmapStep>
                        Introduction of passive income mechanisms <br /> using rent
                    </RoadmapStep>
                </div>
            </div>
            <SvgRoadmapLine />
            <div className={cl(styles.stage, styles.stage4)}>
                <div className={styles.titleLeft}>
                    <RoadmapStage title={"stage 4"} status={'q4 ‘ 2023'} />
                </div>
                <div className={styles.stage4__img}>
                    <img src={ImageCoins} alt="" />
                </div>
                <div className={styles.stage4__flare1}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1130)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1130" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1130" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className={styles.stage4__flare2}>
                    <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_39_1127)">
                            <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
                        </g>
                        <defs>
                            <filter id="filter0_f_39_1127" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1127" />
                            </filter>
                        </defs>
                    </svg>

                </div>
                <div className={styles.stage4__content}>
                    <RoadmapStep>
                        Adding more coins to live lines
                    </RoadmapStep>
                    <RoadmapStep>
                        New lottery types
                    </RoadmapStep>
                    <RoadmapStep>
                        Opportunity to create your own live line
                    </RoadmapStep>
                    <RoadmapStep>
                        <b>To be continued</b>
                    </RoadmapStep>
                </div>
            </div>
        </div>
    );
};

