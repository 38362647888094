import styles from "./SectionsGetYour.module.scss";
import { SvgTitle } from "../../components/svg/SectionsGetYour/SvgTitle";
import img from '../../assets/image/ImageBaby.png'
import flare from '../../assets/image/flares/flareGetYour.png'
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";
import { ButtonPassive } from "../../components/ButtonPassive/ButtonPassive";
import { SocialNetworks } from "../../components/SocialNetworks/SocialNetworks";
import { SvgMobileTitle } from "../../components/svg/SectionsGetYour/SvgMobileTitle";
import { modal } from "../../components/modal";
import { ModalEmail } from "../../components/modal/components/ModalEmail/ModalEmail";



export const SectionsGetYour: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.left}>
                <div className={styles.title}>
                    <SvgTitle />
                </div>
                <div className={styles.title__mobile}>
                    <SvgMobileTitle />
                </div>
                <div className={styles.subtitle}>
                    Don’t waste your time investing in cryptocurrencies or stocks where the price depends on random factors, but invest in events that have only one outcome, “yes” or “no”. Join Pixie and monetize your market vision.
                    <div />
                    Pixie enters 3 rapidly emerging markets at once: <b> Metaverse, NFT and blockchain games</b> in a catchy pixel style that will stay trendy for the next years.
                    <div className={styles.decoding}>
                        *The forecasted market cap of these markets is $13 trillion by 2030.
                    </div>
                </div>

                <div className={styles.btns}>
                    <ButtonActive onClick={() => { window.open("https://game.pixie.fun/", "_blank") }}>Play Pixie</ButtonActive>
                    <ButtonPassive>join community</ButtonPassive>
                </div>
                <button className={styles.join} onClick={() => {
                    modal.open(<ModalEmail />)
                }
                }> Join CIX tokensale Waitlist</button>
                <div className={styles.socialNetworks}>
                    <SocialNetworks />
                </div>

            </div>
            <div className={styles.right}>
                <img src={img} alt="" />
            </div>
            <div className={styles.flare}>
                <img src={flare} alt="" />
            </div>

        </div>
    );
};

