import React from "react";
export const SvgIconCloseMenu: React.FC = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8" clip-path="url(#clip0_125_956)">
                <path d="M1.3125 19.6875L19.6875 1.3125M19.6875 19.6875L1.3125 1.3125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_125_956">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>


    );
};
