import React from "react";

import styles from "./NftHousesAre.module.scss";
import { ButtonActive } from "../../components/ButtonActive/ButtonActive";
import Museum from '../../assets/imageBox/Museum.png'
import StarsBtn from '../../assets/imageBox/StarsBtn.png'
import { modal } from "../../components/modal";
import { ModalMysteryBox } from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";

export const NftHousesAre: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.subTitle}>
                Houses add value to your Land that helps unlock all the earning possibilities of your NFT            </div>
            <div className={styles.center}>
                <div className={styles.left}>
                    <div className={styles.item}>
                        7500 NFT houses
                    </div>
                    <div className={styles.item}>
                        Can have ads on them as additional passive income
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.item}>
                        Customizable to look unique
                    </div>
                    <div className={styles.item}>
                        Can be lent to other players
                    </div>
                </div>
            </div>
            <img className={styles.museum} src={Museum} alt="" />
            <div className={styles.btn}>
                <ButtonActive onClick={() => modal.open(<ModalMysteryBox />)}>buy now</ButtonActive>
            </div>
            <img className={styles.starsBtn} src={StarsBtn} alt="" />
        </div>
    );
};

