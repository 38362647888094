import React, { useContext, useEffect, useState } from "react";
import styles from "./ModalMysteryBox.module.scss";
import { ButtonActive } from "../../../ButtonActive/ButtonActive";
import box from '../../../../assets/imageBox/MysteryBox.png'
import bnb from '../../../../assets/imageBox/Bnb.png'
import { getEthereumAccountInfo, IEthereumAccountInfo } from "../../../../utills/metamask/metamask";
import { Context } from "../../../..";
import { toast } from "react-toastify";
import { BigNumber, ethers } from "ethers";
import { fetchCheckPaymentBnb, fetchCheckPaymentBusd } from "../../../../api/pay";
import { abi } from "./abi";
import { BNB_RECIPIENT_ADDRESS, BUSD_CONTRACT_ADDRESS, BUSD_RECIPIENT_ADDRESS } from "../../../../config/config";
import { modal } from "../../ModalConnector";
import { marketAbi } from "../../../../config/market";


export const ModalMysteryBox: React.FC = () => {
    const { storeUser } = useContext(Context);
    const [step, setStep] = useState(1)
    const login = async () => {
        const eth = await getEthereumAccountInfo(97)
        console.log(eth)
        // const eth = await getInfoMetaMask()
        if (eth?.walletAddress) {
            storeUser.login(eth.walletAddress)
        }

    }
    const purchase = () => {
        login()
        setStep(2)
    }

    const purchaseBNB = async () => {

        try {
            modal.close()
            toast.loading("Payment is expected...");
            console.log(BNB_RECIPIENT_ADDRESS)
            const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(97) as IEthereumAccountInfo;
            const params = [{
                from: ethereumAccount.walletAddress,
                to: BNB_RECIPIENT_ADDRESS,
                value: ethers.utils.parseUnits('0.001', 'ether').toHexString()
            }];
            const transactionHash = await ethereumAccount.provider.send('eth_sendTransaction', params)
            console.log('transactionHash is ' + transactionHash);
            // console.log(ethereumAccount.signer.checkTransaction(transactionHash))
            ethereumAccount.provider.waitForTransaction(transactionHash).then(async function (transaction) {
                console.log(transaction)
                const res = await fetchCheckPaymentBnb(transactionHash)
                toast.dismiss();
                if (res.data.result) {
                    toast.success("Success.");
                    storeUser.setProfileData()

                } else {
                    toast.error("Something went wrong");
                }
            }).catch(() => toast.error("Something went wrong"));


        } catch (error) {
            toast.dismiss();
            toast.error("Something went wrong");
            console.log("error" + error)
        }

    }

    const purchaseBUSD = async () => {
        modal.close()
        toast.loading("Payment is expected...");
        try {
            const amountInTheSmallestUnit = BigNumber.from("10000000000000000");
            const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(97) as IEthereumAccountInfo;
            const busdContractAbi = abi;
            const busdContract = new ethers.Contract(BUSD_CONTRACT_ADDRESS, busdContractAbi, ethereumAccount.signer);
            const tx = await busdContract.transfer(BUSD_RECIPIENT_ADDRESS, amountInTheSmallestUnit);
            const txResponse = await tx.wait();
            console.log(txResponse, tx)
            const res = await fetchCheckPaymentBusd(txResponse.transactionHash)
            toast.dismiss();
            if (res.data.result) {
                toast.success("Success.");
                storeUser.setProfileData()
                modal.close()
            } else {
                toast.error("Something went wrong");
            }

        } catch (error) {
            toast.dismiss();
            toast.error("Something went wrong");
            console.log("error" + error)
        }

    }
    const getNfts = async () => {
        const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(97) as IEthereumAccountInfo;
        const marketContract = new ethers.Contract(marketAbi.address, marketAbi.abi, ethereumAccount.signer);
        const tx = await marketContract.getMyNFTs();
        console.log(tx)
    }
    useEffect(() => {
        getNfts()
    }, [])


    return (
        <div className={styles.content}>
            <img className={styles.box} src={box} alt="" />
            <div className={styles.left}>
                <div className={styles.preTitle}>
                    round 1/5
                </div>
                <div className={styles.title}>
                    mystery box
                </div>
                {step === 1 ?
                    <>
                        <div className={styles.text}>
                            this is a mystery box containing one of the Pixie metaverse assets: pixie nft (50%), nft house (20%), nft land (20%), or nft licence (10%). each of them is unique and will bring you passive income.
                        </div>
                        <div className={styles.blockchain}>
                            <img src={bnb} alt="" />

                            minted on bnb blockchain
                        </div>
                        <div className={styles.buyBlock}>
                            0.5 BNB
                            <ButtonActive onClick={purchase}>purchase</ButtonActive>
                        </div>
                    </>
                    :
                    <div>
                        <div className={styles.blockchain}>
                            <img src={bnb} alt="" />

                            What coin do you want to pay with?
                        </div>
                        <div className={styles.buyBlock}>
                            <ButtonActive onClick={purchaseBNB}>BNB</ButtonActive>
                            <ButtonActive onClick={purchaseBUSD}>BUSD</ButtonActive>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

