import React from "react";

import styles from "./PixieNft.module.scss";

import pixieNft from '../../assets/imageBox/PixieNft.png'
import { ButtonActive } from "../../components/ButtonActiveBlue/ButtonActive";
import { modal } from "../../components/modal";
import { ModalMysteryBox } from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";

export const PixieNft: React.FC = () => {

    return (
        <div className={styles.content}>
            <img className={styles.image} src={pixieNft} alt="" />
            <div className={styles.Maintitle}>
                Pixie NFT <br /> Mystery Box Sale
            </div>
            <div className={styles.subTitleBlock}>
                The only one NFT that brings
                <div className={styles.subTitleGrad}>
                    passive income in USDT every 5 minutes
                </div>
                no matter what your NFT is.
                Every NFT out of 15010 will make you closer to your dream by giving you a stable share of each game played on the platform.
                What do you think, Elon?
                <br />
                Monetize your opinion and let it earn for you.
            </div>

            <div className={styles.buyBox}>
                <div className={styles.buyBox__btn}>
                    <ButtonActive onClick={() => modal.open(<ModalMysteryBox />)}>buy a box</ButtonActive >
                </div>
            </div>
        </div>
    );
};

