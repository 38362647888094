import React from "react";

import styles from "./SectionNftSale.module.scss";
import { ButtonActive } from "../../components/ButtonActiveBig/ButtonActive";
import newText from '../../assets/image/New.svg'
import box from '../../assets/image/Box.png'
import progressBar from '../../assets/image/ProgressBar.svg'
import { useNavigate } from "react-router-dom";

export const SectionNftSale: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.content}>
            <div className={styles.title}>
                <div className={styles.title__text} >
                    nft sale
                    <img src={newText} alt="" />
                </div>

            </div>
            <div className={styles.Maintitle}>
                reinventing nfts
            </div>
            <div className={styles.center}>
                <div className={styles.left}>
                    <div className={styles.leftTitle}>
                        monetize your opinion and let it earn for you
                    </div>
                    <div className={styles.leftText}>
                        Take part in the NFT sale and secure your long-life fully passive income. Every NFT in the drop will let you earn money from the platform games pools, without anything else from your end.
                    </div>
                    <div className={styles.dots}>
                        <div className={styles.item}>
                            <div className={styles.dot} />
                            NFTs are limited.

                        </div>
                        <div className={styles.item}>
                            <div className={styles.dot} />
                            Own2Earn with Pixie
                        </div>
                    </div>
                    <div className={styles.btn} onClick={() => { navigate('/nft'); window.scrollTo({top:0}) }}>
                        <ButtonActive >go to collection page</ButtonActive>
                    </div>

                </div>
                <div className={styles.right}>
                    <div className={styles.rightRound}>round 1/5</div>
                    <div className={styles.rightImg}>
                        <img src={box} alt="" />
                    </div>

                </div>
            </div>
            <div className={styles.progressBar}>
                <img src={progressBar} alt="" />
            </div>
        </div>
    );
};

