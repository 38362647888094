import { Footer } from "./components/Footer/Footer"
import { Header } from "./components/Header/Header"
import { SectionNftSale } from "./sections/SectionNftSale/SectionNftSale"
import { Sections5Mechanisms } from "./sections/Sections5Mechanisms/Sections5Mechanisms"
import { SectionsEcosystem } from "./sections/SectionsEcosystem/SectionsEcosystem"
import { SectionsGetYour } from "./sections/SectionsGetYour/SectionsGetYour"
import { SectionsMission } from "./sections/SectionsMission/SectionsMission"
import { SectionsNoLoss } from "./sections/SectionsNoLoss/SectionsNoLoss"
import { SectionsRevolutionry } from "./sections/SectionsRevolutionry/SectionsRevolutionry"
import { SectionsRoadmap } from "./sections/SectionsRoadmap/SectionsRoadmap"
import { SectionsStaking } from "./sections/SectionsStaking/SectionsStaking"
import { SectionsWeCant } from "./sections/SectionsWeCant/SectionsWeCant"
import { SectionsWhatAre } from "./sections/SectionsWhatAre/SectionsWhatAre"

export const MainPage = () => {
    return <div className="App">
        <>
            <div className='container'>
                <Header />
            </div>
            <div className='container' id='about'>
                <SectionsGetYour />
            </div>
            <div className='container' id='nft'>
                <SectionNftSale />
            </div>

            <div className='container' id='whatAre'>
                <SectionsWhatAre />
            </div>
            <div id='noLoss'>
            </div>
            <SectionsNoLoss />
            <div className='container' id='mission'>
                <SectionsMission />
            </div>
            <div className='container' id='mechanisms'>
                <Sections5Mechanisms />
            </div>
            <div className='container' id='ecosystem'>
                <SectionsEcosystem />
            </div>
            <div className='container' id='staking'>
                <SectionsStaking />
            </div>
            <div className='container' id='tokenomics'>
                <SectionsWeCant />
            </div>

            <div className='container' id='revolutionry'>
                <SectionsRevolutionry />
            </div>
            <div className='container' id='roadmap'>
                <SectionsRoadmap />
            </div>

            <Footer />
        </>
    </div>
}