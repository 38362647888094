import React from "react";
import styles from "./SocialNetworks.module.scss";
import { SvgLogoDiscord } from "../svg/SvgLogoDiscord";
import { SvgLogoIn } from "../svg/SvgLogoIn";
import { SvgLogoTelegram } from "../svg/SvgLogoTelegram";
import { SvgLogoTwitter } from "../svg/SvgLogoTwitter";

export const SocialNetworks: React.FC = () => {
    return (
        <div className={styles.socialNetworks}>
            <div className={styles.icon} onClick={() => { window.open("https://t.me/pixie_chat", "_blank") }}>
                {<SvgLogoTelegram />}
            </div>
            <div className={styles.icon} onClick={() => { window.open("https://twitter.com/Pixie_News", "_blank") }}>
                {<SvgLogoTwitter />}
            </div>
            <div className={styles.icon} onClick={() => { window.open("https://discord.gg/NzV3sjEg", "_blank") }}>
                {<SvgLogoDiscord />}
            </div>
            <div className={styles.icon} onClick={() => { window.open("https://www.linkedin.com/company/pixiefun/", "_blank") }}>
                {<SvgLogoIn />}
            </div>
        </div>
    );
};

