const MAINNETWORK = "mainnet";
const TESTNETWORK = "testnet";

// Here you can change the network !
const NET:string= TESTNETWORK

const LOCAL_SERVER_DOMAIN = "http://localhost:4003";
const MAIN_SERVER_DOMAIN = "https://api.pixudi.com";


export const SERVER_DOMAIN = LOCAL_SERVER_DOMAIN;


const BNB_RECIPIENT_ADDRESS_TESTNET = '0x9A0aA4C5B3b029e17d91f861745C0889001dd9B0';
const BNB_RECIPIENT_ADDRESS_MAINNET = '';

const BUSD_CONTRACT_ADDRESS_TESTNET = '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee';
const BUSD_CONTRACT_ADDRESS_MAINNET = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';

const BUSD_RECIPIENT_ADDRESS_TESTNET = '0x9A0aA4C5B3b029e17d91f861745C0889001dd9B0';
const BUSD_RECIPIENT_ADDRESS_MAINNET = '';

export const BUSD_CONTRACT_ADDRESS = NET === 'mainnet' ? BUSD_CONTRACT_ADDRESS_MAINNET : BUSD_CONTRACT_ADDRESS_TESTNET;
export const BUSD_RECIPIENT_ADDRESS = NET === 'mainnet' ? BUSD_RECIPIENT_ADDRESS_MAINNET : BUSD_RECIPIENT_ADDRESS_TESTNET;
export const BNB_RECIPIENT_ADDRESS = NET === 'mainnet' ? BNB_RECIPIENT_ADDRESS_MAINNET : BNB_RECIPIENT_ADDRESS_TESTNET;


