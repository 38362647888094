import React from "react";
import styles from "./RoadmapStep.module.scss";
import { SvgRadioButton } from "../svg/SvgRadioButton";

export const RoadmapStep: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className={styles.step}>
            <div className={styles.step__btn}>
                <SvgRadioButton />
            </div>
            <div className={styles.step__text}>
                {children}
            </div>
        </div>
    );
};

