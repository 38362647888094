import React, { useEffect, useState } from 'react';
import EventEmitter from 'events';
import Modal from './Modal';
import * as modals from './components'

const ev = new EventEmitter();
export interface IModalParams {
  className?: string;
  componentProps?: React.ComponentProps<any>;
}

export const modal = {
  open: (component: React.ReactNode | keyof typeof modals, props?: IModalParams) => {
    ev.emit('open', { component, props });
  },
  close: () => {
    ev.emit('close');
  }
};

export const ModalConnector = () => {
  const [active, setActive] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [props, setProps] = useState<IModalParams>({});

  useEffect(() => {
    const openModal = ({ component, props } : { component: React.ReactNode | keyof typeof modals, props: IModalParams }) => {
      let newComponent = component;
      if (typeof component === 'string') {
        const Modal = modals[component as keyof typeof modals];
        newComponent = <Modal {...props.componentProps} />
      }
      setContent(newComponent);
      setProps(props);
      setActive(true);
    };

    const closeModal = () => {
      setActive(false);
    };

    ev.on('open', openModal);
    ev.on('close', closeModal);

    return () => {
      ev.off('open', openModal);
      ev.off('close', closeModal);
    }
  }, []);

  return (
    <Modal
      active={active}
      setActive={setActive}
      {...props}
    >
      {content}
    </Modal>
  )
};
