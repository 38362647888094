import React from "react";

import styles from "./NftLicenceIsRarest.module.scss";
import { ButtonActive } from "../../components/ButtonActiveMedium/ButtonActive";
import box from '../../assets/imageBox/BoxGrad.png'
import StarsBtn from '../../assets/imageBox/StarsBtn.png'
import { modal } from "../../components/modal";
import { ModalMysteryBox } from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";

export const NftLicenceIsRarest: React.FC = () => {

    return (
        <div className={styles.content}>
            <div className={styles.subTitleBlock}>
                <div className={styles.subTitle}>
                    nft licence is the rarest asset that can be found in the mystery box
                </div>
                <div className={styles.subTitleLine} />
            </div>
            <div className={styles.preText}>
                <div className={styles.preSquare} />
                864 licence owners will receive 2% of every game pool                <div className={styles.preSquare} />
            </div>
            <div className={styles.center}>
                <img src={box} alt="" />
                <div className={styles.text}>
                    There are 4 Licence exclusivity levels that affect the share that you passively get from the pool. just aaply the licence to any house and land and Earn up to 120x over only house owners !                </div>
            </div>
            <ButtonActive onClick={() => modal.open(<ModalMysteryBox />)}>test your luck</ButtonActive>
            <img className={styles.stars} src={StarsBtn} alt="" />
        </div>
    );
};

