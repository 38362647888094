import React, { useState } from "react";
import styles from "./SectionsEcosystem.module.scss";
import "./SectionsEcosystem.scss";
import img from '../../assets/image/Tree.png'
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Swiper as SwiperType } from "swiper";
import { SlideCard } from "../../components/SlideCard/SlideCard";
import { SvgArrowL } from "../../components/svg/SvgArrowL";
import { SvgArrowR } from "../../components/svg/SvgArrowR";
import flare from '../../assets/image/flares/flareEcosystem.png'

export const SectionsEcosystem: React.FC = () => {
    const [swiper, setSwiper] = useState<SwiperType>();

    return (
        <div className={styles.content}>
            <div className={styles.title}>
                Pixie ecosystem
                <div className={styles.subtitle}>
                    under development
                </div>
            </div>
            <div className={styles.slider}>
                <Swiper
                    mousewheel
                    slidesPerView={5}
                    onSwiper={(swiper) => { setSwiper(swiper) }}
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{ clickable: true }}
                    className="mySwiper"
                    slideClass="ecosystemSlide"
                    slideNextClass="ecosystemSlideNext"
                    slidePrevClass="ecosystemSlidePrev"
                    slideActiveClass="ecosystemSlideActive"
                    initialSlide={2}
                    centeredSlides
                    breakpoints={{
                        1330: {
                            slidesPerView: 5,
                        },
                        200: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    <SwiperSlide
                        className="ecosystemSlide"
                    >
                        <SlideCard
                            title="leaderboards with rewards"
                            text={"Real-time leaderboards of most active Pixies with regular rewards."} />
                    </SwiperSlide>
                    <SwiperSlide
                        className="ecosystemSlide">
                        <SlideCard
                            title="earning options"
                            text={"Stay2Earn, Play2Earn, Own2Earn, Refer2Earn, Delegate2Earn. Everything to make you rich."} />
                    </SwiperSlide>
                    <SwiperSlide
                        className="ecosystemSlide">
                        <SlideCard
                            title="NFT marketplace"
                            text={"Wanna be unique? Customize your Pixie, buildings and profile, buy some short term skills. Sell rare items, create your own ones and trade them on the marketplace."} />
                    </SwiperSlide>
                    <SwiperSlide
                        className="ecosystemSlide">
                        <SlideCard
                            title="individual pixies"
                            text={"Make your Pixie look unique, become an Icon of the Pixie world and make if your brand. Upgrade your Pixie and make it your main tool for earning money."} />
                    </SwiperSlide>
                    <SwiperSlide
                        className="ecosystemSlide">
                        <SlideCard
                            title="customized buildings in metaverse"
                            text={"Customize your buildings as you wish, your hands are not tied to any limitations. Earn money passively when a game takes place in your house, let people enjoy your creativity."} />
                    </SwiperSlide>
                </Swiper>
                <div className={styles.sliderArrowL} onClick={() => swiper?.slidePrev()}><SvgArrowL /></div>
                <div className={styles.sliderArrowR} onClick={() => swiper?.slideNext()}><SvgArrowR /></div>
            </div>
            <div className={styles.tree}>
                <img src={img} alt="" />
            </div>
            <div className={styles.flare}>
                <img src={flare} alt="" />
            </div>
        </div>
    );
};

